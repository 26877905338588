<template>
  <div class="evo-wrapper">
    <!-- breadcrumbs -->
    <div class="evo-breadcrumbs-wrapper">
      <v-breadcrumbs :items="breadcrumbs" class="evo-breadcrumbs">
        <template #divider>
          <v-icon>$vuetify.icons.mdiChevronRight</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.to" :disabled="item.disabled" exact>
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>

    <v-row class="evo-brand">
      <v-col cols="6" data-aos="fade-up">
        <v-card class="rounded-xl" elevation="6">
          <template v-if="brand === 'BankTransfer'">
            <div class="brand-icon-wrapper">
              <img loading="lazy"  class="brand-icon" :src="brandFull.smallLogo" />
            </div>
          </template>
          <template v-else>
            <v-img :src="brandFull.logo"> </v-img>
          </template>
        </v-card>
      </v-col>
      <v-col cols="6" data-aos="fade-up">
        <div class="brand-content">
          <div class="evo-quote-mark">
            <img loading="lazy"  class="evo-img-auto" src="@/assets/quote-mark.png" />
          </div>
          <div class="content evo-opensans-regular">
            <div class="brand-name evo-text-29">{{ brandFull.name }}</div>
            <div
              class="brand-tag evo-text-13"
              v-for="(tag, i) in brandFull.typeTags"
              :key="i"
            >
              {{ tag }}
            </div>
            <div class="brand-desc evo-text-15">
              {{ $t(brandFull.longDesc) }}
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- Currency / Countries-->
    <v-row class="evo-brand-info">
      <v-col cols="6">
        <div class="d-flex flex-column">
          <div class="evo-title" data-aos="fade-up">
            {{ $t("payment_methods.transaction_currency") }}
          </div>
          <div class="evo-currency-desc evo-text-15 evo-opensans-regular" data-aos="fade-up" data-aos-delay="100">
            <template v-if="brandFull.transCurrency[0] === 'ALL'">
              {{ $t("payment_methods.all_currencies") }}
            </template>
            <template v-else>
              {{ brandFull.transCurrency.join() }}
            </template>
          </div>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="d-flex flex-column">
          <div class="evo-title" data-aos="fade-up">
            {{ $t("payment_methods.countries") }}
          </div>
          <div class="evo-country-desc evo-text-15 evo-opensans-regular" data-aos="fade-up" data-aos-delay="100">
            <template v-if="brandFull.countries[0] === 'ALL'">
              {{ $t("payment_methods.all_countries") }}
            </template>
            <template v-else>
              <div
                class="d-flex align-center evo-country-item"
                v-for="country in brandFull.countries"
                :key="country"
              >
                <span
                  class="fi fis country-img"
                  :class="PaymentCountries[country].iconfont"
                ></span>
                <div class="country-name evo-text-17 evo-opensans-regular">
                  {{ PaymentCountries[country].name }}
                </div>
              </div>
            </template>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- Scenarios -->
    <div class="evo-scenarios-wrapper">
      <div class="evo-title" data-aos="fade-up">
        {{ $t("payment_methods.payment_scenarios") }}
      </div>
      <v-row>
        <v-col
          cols="4"
          v-for="scene in paymentScenarios"
          :key="scene"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div class="d-flex flex-column evo-item-wrapper">
            <img loading="lazy"  class="evo-item-img" :src="logoMap[scene]" />
            <div
              class="evo-item-title evo-text-17 evo-opensans-regular"
              v-for="(type, index) in brandFull.paymentScenarios[scene]"
              :key="index"
            >
              {{ type }}
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- Functions -->
    <div class="evo-function-wrapper">
      <div class="evo-title" data-aos="fade-up">
        {{ $t("payment_methods.functions") }}
      </div>
      <div class="function-group" data-aos="fade-up" data-aos-delay="100">
        <div
          v-for="item in brandFull.functions"
          :key="item"
          class="function-item evo-text-13 evo-opensans-regular"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { paymentMethodsDetailMixin } from '@/mixins/payment-methods.js';
export default {
  name: "EvoPaymentMethodsDetailSection",
  mixins: [paymentMethodsDetailMixin]
};
</script>
<style lang="scss" scoped>
::v-deep(.evo-breadcrumbs) {
  padding-top: 0;
  padding-left: 0;
  .v-breadcrumbs__item {
    color: #222a41;
    font-size: 15px;
    font-family: "OpenSans-Regular";
  }
}
.evo-wrapper {
  color: #222a41;
  position: relative;
  .evo-title {
    font-size: 20px;
    font-family: "OpenSans-Regular";
    font-weight: 600;
    line-height: 43px;
  }
}
.evo-breadcrumbs-wrapper {
  margin-top: 38px;
  margin-bottom: 20px;
}
.evo-brand {
  margin-bottom: 45px;
  .brand-icon-wrapper {
    height: 278px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .brand-icon {
      width: 86px;
    }
  }
  .brand-content {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    .evo-quote-mark {
      width: 37px;
      height: 33px;
      overflow: hidden;
      margin-bottom: 17px;
    }
    .content {
      padding-left: 62px;
      .brand-name {
        font-weight: 600;
        line-height: 43px;
        margin-bottom: 24px;
      }
      .brand-tag {
        display: inline-block;
        padding: 0 8px;
        margin-bottom: 17px;
        height: 34px;
        line-height: 34px;
        color: #ffffff;
        background: #459eff;
        border-radius: 17px;
        & + .brand-tag {
          margin-left: 8px;
        }
      }
      .brand-desc {
        min-height: 96px;
        color: #6d7783;
        line-height: 24px;
      }
    }
  }
}
.evo-brand-info {
  margin-bottom: 65px;
  .evo-title {
    margin-bottom: 14px;
  }
  .evo-country-desc,
  .evo-currency-desc {
    color: #6d7783;
    height: 26px;
    display: flex;
    align-items: center;
  }
  .evo-country-desc {
    .evo-country-item {
      & + .evo-country-item {
        margin-left: 14px;
      }
    }
    .country-img {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      margin-right: 14px;
    }
    .country-name {
      color: #1f252d;
    }
  }
}
.evo-scenarios-wrapper {
  margin-bottom: 90px;
  .evo-title {
    margin-bottom: 20px;
  }
  .evo-item-img {
    width: 68px;
    margin-bottom: 23px;
  }
  .evo-item-title {
    color: #000000;
    line-height: 28px;
  }
  .evo-item-title + .evo-item-title {
    margin-top: 10px;
  }
}
.evo-function-wrapper {
  .evo-title {
    margin-bottom: 30px;
  }
  .function-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .function-item {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      height: 34px;
      background: #ffffff;
      border: 1px solid #aeb7c1;
      color: #6d7783;
      line-height: 20px;
    }
    .function-item + .function-item {
      margin-left: 8px;
    }
  }
}
</style>
