<template>
  <div class="evo-wrapper evo-f-center">
    <div class="evo-mobile">
      <!-- breadcrumbs -->
      <div class="evo-breadcrumbs-wrapper">
        <v-breadcrumbs :items="breadcrumbs" class="evo-breadcrumbs">
          <template #divider>
            <v-icon>$vuetify.icons.mdiChevronRight</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :to="item.to" :disabled="item.disabled" exact>
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </div>

      <v-row class="evo-brand">
        <v-col cols="12">
          <v-card class="rounded-xl evo-brand-card" elevation="0">
            <template v-if="brand === 'BankTransfer'">
              <div class="brand-icon-wrapper">
                <img loading="lazy" class="brand-icon" :src="brandFull.smallLogo" />
              </div>
            </template>
            <template v-else>
              <div class="evo-brand-wrapper">
                <img class="evo-brand-img" :src="brandFull.logo" />
              </div>
            </template>
          </v-card>
        </v-col>
        <v-col cols="12">
          <div class="brand-content">
            <div class="evo-quote-mark">
              <img loading="lazy" class="evo-img-auto" src="@/assets/quote-mark.png" />
            </div>
            <div class="content evo-opensans-regular">
              <div class="brand-name evo-text-62">{{ brandFull.name }}</div>
              <div class="brand-tag evo-text-27" v-for="(tag, i) in brandFull.typeTags" :key="i">
                {{ tag }}
              </div>
              <div class="brand-desc evo-text-33">
                {{ $t(brandFull.longDesc) }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- Currency / Countries-->
      <v-row class="evo-brand-info">
        <v-col cols="12">
          <div class="d-flex flex-column">
            <div class="evo-title">
              {{ $t('payment_methods.transaction_currency') }}
            </div>
            <div class="evo-currency-desc evo-text-33 evo-opensans-regular">
              <template v-if="brandFull.transCurrency[0] === 'ALL'">
                {{ $t('payment_methods.all_currencies') }}
              </template>
              <template v-else>
                {{ brandFull.transCurrency.join() }}
              </template>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="d-flex flex-column">
            <div class="evo-title">
              {{ $t('payment_methods.countries') }}
            </div>
            <div class="evo-country-desc evo-text-33 evo-opensans-regular">
              <template v-if="brandFull.countries[0] === 'ALL'">
                {{ $t('payment_methods.all_countries') }}
              </template>
              <template v-else>
                <div class="d-flex align-center evo-country-item" v-for="country in brandFull.countries" :key="country">
                  <span class="fi fis country-img" :class="PaymentCountries[country].iconfont"></span>
                  <div class="country-name evo-text-33 evo-opensans-regular">
                    {{ PaymentCountries[country].name }}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- Scenarios -->
      <div class="evo-scenarios-wrapper">
        <div class="evo-title">
          {{ $t('payment_methods.payment_scenarios') }}
        </div>
        <v-row>
          <v-col cols="6" v-for="scene in paymentScenarios" :key="scene">
            <div class="d-flex flex-column evo-item-wrapper">
              <img loading="lazy" class="evo-item-img" :src="logoMap[scene]" />
              <div class="evo-item-title evo-text-36 evo-opensans-regular" v-for="(type, index) in brandFull.paymentScenarios[scene]" :key="index">
                {{ type }}
              </div>
            </div>
          </v-col>
        </v-row>
      </div>

      <!-- Functions -->
      <div class="evo-function-wrapper">
        <div class="evo-title">
          {{ $t('payment_methods.functions') }}
        </div>
        <div class="function-group">
          <div v-for="item in brandFull.functions" :key="item" class="function-item evo-text-28 evo-opensans-regular">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { paymentMethodsDetailMixin } from '@/mixins/payment-methods.js';
export default {
  name: 'EvoMPaymentMethodsDetailSection',
  mixins: [paymentMethodsDetailMixin]
};
</script>
<style lang="scss" scoped>
::v-deep(.evo-breadcrumbs) {
  padding-top: 0;
  padding-left: 0;
  .v-breadcrumbs__item {
    color: #222a41;
    font-size: 33px;
    font-family: 'OpenSans-Regular';
  }
}
.evo-wrapper {
  color: #222a41;
  position: relative;
  .evo-title {
    font-size: 43px;
    font-family: 'OpenSans-Regular';
  }
}
.evo-breadcrumbs-wrapper {
  margin-top: 38px;
  margin-bottom: 20px;
}
.evo-brand {
  margin-bottom: 45px;

  .evo-brand-card {
    box-shadow: 0px 20px 63px 0px rgba(0, 39, 122, 0.18) !important;
  }

  .evo-brand-wrapper {
    height: 486px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .evo-brand-img {
      width: 520px;
      height: auto;
    }
  }

  .brand-icon-wrapper {
    height: 486px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .brand-icon {
      width: 240px;
    }
  }

  .brand-content {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    .evo-quote-mark {
      width: 80px;
      height: auto;
      overflow: hidden;
      margin-top: 32px;
      margin-bottom: 32px;
    }
    .content {
      .brand-name {
        font-weight: 600;
        margin-bottom: 64px;
      }
      .brand-tag {
        display: inline-block;
        padding: 32px 48px;
        margin: auto;
        margin-bottom: 48px;
        text-align: center;
        color: #ffffff;
        background: #459eff;
        border-radius: 54px;
        & + .brand-tag {
          margin-left: 8px;
        }
      }
      .brand-desc {
        color: #6d7783;
      }
    }
  }
}

.evo-brand-info {
  margin-bottom: 65px;
  .evo-title {
    margin-bottom: 32px;
  }
  .evo-country-desc,
  .evo-currency-desc {
    color: #6d7783;
    height: 26px;
    display: flex;
    align-items: center;
  }
  .evo-country-desc {
    .evo-country-item {
      & + .evo-country-item {
        margin-left: 14px;
      }
    }
    .country-img {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      margin-right: 24px;
    }
    .country-name {
      color: #1f252d;
    }
  }
}
.evo-scenarios-wrapper {
  margin-bottom: 92px;

  .evo-item-wrapper {
    margin-top: 32px;
  }
  .evo-title {
    margin-bottom: 20px;
  }
  .evo-item-img {
    width: 146px;
    margin-bottom: 32px;
  }
  .evo-item-title {
    color: #000000;
    line-height: 28px;
  }
  .evo-item-title + .evo-item-title {
    margin-top: 24px;
  }
}
.evo-function-wrapper {
  .evo-title {
    margin-bottom: 30px;
  }
  .function-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 48px;

    .function-item {
      display: flex;
      align-items: center;
      padding: 16px 32px;
      background: #ffffff;
      border: 1px solid #aeb7c1;
      color: #6d7783;
    }

    .function-item {
      margin-right: 32px;
      margin-bottom: 32px;
    }
  }
}
</style>
