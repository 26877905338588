<template>
  <div class="evo-wrapper">
    <template v-if="$vuetify.breakpoint.mobile">
      <EvoMPaymentMethodsDetailSection />
    </template>
    <template v-else>
      <EvoPaymentMethodsDetailSection />
    </template>
  </div>
</template>
<script>
import EvoPaymentMethodsDetailSection from '@/components/payment_methods_sections/Detail.vue';
import EvoMPaymentMethodsDetailSection from '@/mobile/components/payment_methods_sections/MDetail.vue';
export default {
  name: 'EvoMediaNewsDetailView',
  components: {
    EvoPaymentMethodsDetailSection,
    EvoMPaymentMethodsDetailSection
  }
};
</script>
<style lang="scss" scoped>
.evo-wrapper {
  color: #222a41;
  position: relative;
}
</style>
